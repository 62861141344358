import React, { useEffect } from "react";
import AppWrapper from "../layouts/AppWrapper";
import Tour from "../components/Player/Tour";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentTourStep } from "../redux/user/userSlice";

function Player() {
  const user = useSelector((state) => state.user);
  const tour = useSelector((state) => state.tour.tour);
  const dispatch = useDispatch();
  const { currentTourStep, currentStep } = user;

  useEffect(() => {
    dispatch(
      setCurrentTourStep(
        tour.tourSteps.find(
          (tourStep) => parseInt(tourStep.position_nb) === user.currentStep
        )
      )
    );
  }, [currentStep]);

  return (
    <AppWrapper>
        <Tour currentTourStep={currentTourStep} />
    </AppWrapper>
  );
}

export default Player;
