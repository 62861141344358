import { configureStore } from "@reduxjs/toolkit";
import tourReducer from "./tour/tourSlice";
import userReducer from "./user/userSlice";

export const store = configureStore({
  reducer: {
    tour: tourReducer,
    user: userReducer,
  },
});
