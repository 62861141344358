import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isTicketPurchased: false,
  currentTourStep: {},
  currentStep: 1,
};

const userSlice = createSlice({
  name: "User",
  initialState,
  reducers: {
    setNextTourStep(state) {
      state.currentStep += 1;
    },
    setPreviousTourStep(state) {
      if (state.currentStep > 1) {
        state.currentStep -= 1;
      }
    },
    changeCurrentStep(state, action) {
      state.currentStep = action.payload;
    },
    setCurrentTourStep(state, action) {
      state.currentTourStep = action.payload;
    },
    changeTicketStatus(state, action) {
      state.isTicketPurchased = action.payload;
    },
  },
});

export const {
  setNextTourStep,
  setPreviousTourStep,
  setCurrentTourStep,
  changeTicketStatus,
  changeCurrentStep,
} = userSlice.actions;
export default userSlice.reducer;
