import React from "react";
import Navbar from "../components/Player/Navbar";

export default function AppWrapper({ children }) {
  const location = window.location.pathname.split("/");

  return (
    <div className="container mb-5">
      {(!(location.includes('introduction') || location.includes('feedback'))) && <Navbar />}
      {children}
    </div>
  );
}
