import { createBrowserRouter } from "react-router-dom";
import Player from "./pages/Player";
import ProtectedRoutes from "./utils/ProtectedRoutes";
import TicketPage from "./pages/TicketPage";
import Overview from "./pages/Overview";
import MainPage from "./pages/MainPage";
import Feedback from "./pages/Feedback";
import Introduction from "./pages/Introduction";
import NoMatch from "./pages/NoMatch";

export const router = createBrowserRouter([
  { path: "/", element: <MainPage /> },
  { path: "/:language_code/introduction", element: <Introduction /> },
  {
    path: "/:language_code/player",
    element: (
      <ProtectedRoutes>
        <Player />
      </ProtectedRoutes>
    ),
  },
  {
    path: "/:language_code/overview",
    element: <Overview />,
  },
  {
    path: "/:language_code/ticket",
    element: <TicketPage />,
  },
  {
    path: "/:language_code/feedback",
    element: <Feedback />,
  },
  {
    path: "*",
    element: <NoMatch />,
  },
]);
