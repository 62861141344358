import React from "react";
import { Link } from "react-router-dom";
import ltFlag from "../assets/images/lt.png";
import enFlag from "../assets/images/en.png";
import deFlag from "../assets/images/de.png";
import logo from "../assets/images/logo.png";

export default function MainPage() {
  /* text/minhscreen */
  return (
    <div
      className="
      mt-4 md:mt-8 mb-8
    min-h-screen flex flex-col justify-start lg:justify-center items-center pb-8 pt-12 lg:pt-0"
    >
      <div className="">
        <main className="flex flex-col items-center justify-start">
          <div className="w-[300px] mb-8">
            <img className="w-full" src={logo} alt="" />
          </div>
          {/* select options */}
          <ul className="flex flex-col gap-4 text-white w-full">
            <li>
              <Link
                to="/lt/introduction"
                className="text-[16px] font-medium flex items-center justify-between px-5 py-1 bg-[#D54746] rounded-3xl"
              >
                <img className="w-7 h-7 rounded-full" src={ltFlag} alt="" />
                <p className="text-center">Lietuviškai</p>
                <div className="w-7 h-7"></div>
              </Link>
            </li>

            <li>
              <Link
                to="/en/introduction"
                className="text-[16px] font-medium flex items-center justify-between px-5 py-1 bg-[#D54746] rounded-3xl"
              >
                <img className="w-7 h-7 rounded-full" src={enFlag} alt="" />
                <p className="text-center">English</p>
                <div className="w-7 h-7"></div>
              </Link>
            </li>
            <li>
              <Link
                to="/de/introduction"
                className="text-[16px] font-medium flex items-center justify-between px-5 py-1 bg-[#D54746] rounded-3xl"
              >
                <img className="w-7 h-7 rounded-full" src={deFlag} alt="" />
                <p className="text-center">German</p>
                <div className="w-7 h-7"></div>
              </Link>
            </li>
          </ul>
        </main>
      </div>
    </div>
  );
}
