import React, { useState } from "react";
import AppWrapper from "../layouts/AppWrapper";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  changeCurrentStep,
  changeTicketStatus,
  setCurrentTourStep,
} from "../redux/user/userSlice";
import translate from "../utils/translations";

export default function TicketPage() {
  /* 433190 */
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { tourSteps } = useSelector((state) => state.tour.tour);
  const user = useSelector((state) => state.user);
  const { language_code } = useParams();
  const [isTicketValid, setIsTicketValid] = useState(null);

  async function checkTicketCode(e) {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const code = formData.get("code");

    const url =
      process.env.REACT_APP_NODE_ENV === "production"
        ? process.env.REACT_APP_PROD_API_URL
        : process.env.REACT_APP_DEV_API_URL;

    const response = await fetch(
      `${url}/api/${language_code}/checkTicket/${code}`
    );
    const data = await response.json();
    const { ticketValid } = data;

    if (ticketValid) {
      dispatch(changeTicketStatus(true));
      dispatch(
        setCurrentTourStep(
          tourSteps.find(
            (tourStep) => parseInt(tourStep.position_nb) === user.currentStep
          )
        )
      );
      dispatch(changeCurrentStep(2));
      navigate(`/${language_code}/player`);
    } else {
      setIsTicketValid(ticketValid);
    }
  }

  return (
    <AppWrapper>
      <div className="h-screen flex justify-center items-center mt-[-50px]">
        <div className="">
          <h1 className="text-black text-2xl font-bold mb-8 text-center">
            {translate(language_code, "Norint tęsti, įsigykite bilietą kasose")}
          </h1>

          {isTicketValid === false && (
            <p className="text-red-500 text-xl mb-4 text-center font-normal bg-red-100 border-red-200 rounded-2xl p-4">
              {translate(
                language_code,
                "Šio kodo neradome. Bandykite dar kartą."
              )}
            </p>
          )}

          <form
            className="flex flex-col items-center justify-center"
            onSubmit={checkTicketCode}
          >
            <label className="font-normal text-[20px] mb-4">
              {translate(language_code, "Įveskite kodą")}:
            </label>
            <input
              className="w-full p-2 border border-[#9E9E9E] rounded-lg mb-2"
              name="code"
              type="text"
            />

            <p className="font-normal mb-12 w-full text-[20px] text-center">
              {translate(
                language_code,
                "Surinktos lėšos bus panaudotos kitų projekto dalių kūrimui ir tobulinimui."
              )}
            </p>

            <button
              type="submit"
              className="inline-block text-[16px] font-normal w-full text-center px-5 py-3 bg-[#D54746] rounded-3xl text-white mb-4"
            >
              {translate(language_code, "Tęsti")}
            </button>
          </form>
          <button
            onClick={() => {
              dispatch(changeCurrentStep(1));
              navigate(`/${language_code}/player`);
            }}
            className="inline-block text-[16px] font-normal w-full text-center px-5 py-3 bg-[#1F1F1F] rounded-3xl text-white"
          >
            {translate(language_code, "Klausytis nemokamos versijos")}
          </button>
        </div>
      </div>
    </AppWrapper>
  );
}
