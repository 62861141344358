import React from "react";
import AppWrapper from "../layouts/AppWrapper";
import { useParams } from "react-router-dom";
import translate from "../utils/translations";

export default function LoadingScreen({ progress }) {
  const {language_code} = useParams();

  return (
    <AppWrapper>
      <div className="h-screen flex flex-col items-center justify-center gap-8">
        <h1 className="text-black text-2xl font-bold text-center">{translate(language_code,"Kraunasi")}...</h1>
        <div className="loader-container border border-gray-300 bg-[#D9D9D9] w-full h-3 rounded-md overflow-hidden">
          <div
            className="loader w-0 bg-[#D54746] h-full"
            style={{ width: progress + "%" }}
          ></div>
        </div>
      </div>
    </AppWrapper>
  );
}
