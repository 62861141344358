import React, { useEffect, useState } from "react";
import AppWrapper from "../layouts/AppWrapper";
import translate from "../utils/translations";
import parse from "html-react-parser";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

export default function IntroductionScreen({ setLoadingScreen }) {
  const { language_code } = useParams();
  const navigate = useNavigate();
  const tour = useSelector((state) => state.tour.tour);
  const { isLoading } = tour;

  const [introText, setIntroText] = useState("");

  async function fetchIntroText() {
    const url =
      process.env.REACT_APP_NODE_ENV === "production"
        ? process.env.REACT_APP_PROD_API_URL
        : process.env.REACT_APP_DEV_API_URL;
    const response = await fetch(`${url}/api/${language_code}/getTranslations`);
    const data = await response.json();

    setIntroText(data);
  }

  useEffect(() => {
    fetchIntroText();
  }, []);

  return (
    <AppWrapper>
      <div className="h-screen flex justify-center items-center  mt-[-20px]">
        <div className="">
          <h1 className="text-black text-2xl font-bold mb-8 text-center">
            {translate(language_code, "Jūsų kelionė prasideda")}
          </h1>
          <div className="flex flex-col justify-center gap-4 mb-8 font-normal text-[18px]">
            {/* {parse(translate(language_code, "intro_text"))} */}
            {parse(introText)}
          </div>
          <button
            onClick={() => {
              if (!isLoading) {
                navigate(`/${language_code}/player`);
              } else {
                setLoadingScreen(true);
              }
            }}
            className="inline-block text-[16px] font-medium w-full text-center px-5 py-3 bg-[#D54746] rounded-3xl text-white"
          >
            {translate(language_code, "Supratau")}
          </button>
        </div>
      </div>
    </AppWrapper>
  );
}
